<template>
  <div>
    <p v-for="item in history" :key="item.id">
      {{ item.id }}
    </p>
  </div>
</template>

<script>
export default {
  name: "History",
  methods: {},
  computed: {},
  props: {
    history: {
      type: Array,
      required: true,
    },
  },
};
</script>
